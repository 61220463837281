import React from "react";

import { Layout, SEO } from '../../../components/structure';
import { Button, Content, Hero, PremUpgradeContainer, Testimonial, PointsLoyalty, CallToAction, LoyaltyCommerce } from "../../../components/blocks"
import styled, { css } from "styled-components";
import { brand, font, mq } from "../../../styles";
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"



const IndustryRailPage = (props) => {

  const pageQuery = useStaticQuery(graphql`
    query {
      hero: file(base: { eq: "Plusgrade-Hero-Banner-Rail.jpg" }) {
       ...FullHeroBackground
      },
      rightArrowIcon: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
      railIcon: file(base: {eq: "rail.png"}) {
        childImageSharp {
          fixed(width: 40, height: 30, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      optimizeIcon: file(base: { eq: "optimized-white.png" }) {
        childImageSharp {
            fixed(width: 60, height: 52, webpQuality: 100) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
      }
      merchandiseIcon: file(base: { eq: "merchandise-white.png" }) {
          childImageSharp {
              fixed(width: 60, height: 70, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      engageIcon: file(base: { eq: "engage-white.png" }) {
          childImageSharp {
              fixed(width: 60, height: 52, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      announcementIcon: file(base: { eq: "announce-orange.png" }) {
          childImageSharp {
              fixed(width: 67, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      searchIcon: file(base: { eq: "search-orange.png" }) {
          childImageSharp {
              fixed(width: 60, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      seatPlusIcon: file(base: { eq: "seatPlus-orange.png" }) {
          childImageSharp {
              fixed(width: 60, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      syncIcon: file(base: { eq: "sync-orange.png" }) {
          childImageSharp {
              fixed(width: 73, height: 60, webpQuality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
              }
          }
      }
      revPlatformBackground: file(base: { eq: "IndustryRailRevenuePlatform.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      railTestimonials: file(base: { eq: "IndustryRailTestimonials.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 600, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      superheroArrow: file(base: { eq: "ArrowSuperhero.png" }) {
        childImageSharp {
          fixed(width: 500, height: 300, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      mobileDevice: file(base: { eq: "Product-Rail-Premium-Upgrade.png" }) {
        childImageSharp {
            fluid(maxWidth: 260, webpQuality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
    }
    }
  `);

  const PlatformRevenueContainer = styled.div`
  
  ${ font.imports.sherika }
    width: 100%;
    height: auto;
    min-height: 700px;
    margin: auto;
    position: relative;

    .background-con {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      filter: grayscale(1) contrast(1.2);

      &:after {
        content: '';
        position: absolute;
        z-index: 350;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:linear-gradient(0deg, rgba(0, 4, 20, 0.65), rgba(0, 4, 20, 0.65));
      }

      .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .content-con {
      position: relative;
      z-index: 400;
      width: 95%;
      max-width: 1100px;
      margin: auto;
      height: 100%;
      padding: 75px 0;

      h3 {
        font-size: 38px;
        text-align: center;
        color: ${brand.colors.pg_orange};
        font-weight: 600;
        text-shadow: 0 0 40px ${brand.colors.pg_primary_dark};
      }

      .icon-content-con {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        top: 130px;
        margin-bottom: 100px;

        @media only screen and ${mq.maxMd} {
          flex-direction: column;
          align-items: center;
        }
      }
    }

  `;

  const OutperformContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    background-color: ${brand.colors.pg_primary_light};

    .left-section {
      flex-basis: 50%;
      height: 450px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .right-section {
      height: 450px;
      flex-basis: 50%;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 90px;

      .blue-highlight {
        font-size: 80px;
        color: ${brand.colors.pg_blue};
        display: block;
      }

      .copy {
        display: block;
        font-size: 24px;
        color: #000414;
        width: 54%;
        max-width: 350px;
        margin: 15px 0;
      }
    }
  `;

  const PlatformPerksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    max-width: 800px;
    margin: auto;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    gap: 40px;
    padding: 60px 0;

    .headline-highlight{
      display: none;
    }
  `


  return (
    <Layout>
      <SEO title="Rail | Plusgrade" 
            description={'Générez des expériences clients incroyable avec des solutions de surclassement adapté à votre marque.'}
            keywords={'rail, train, upgrades'}/>
      <Content whiteBG>
        <Hero 
          title={'Rail'}
          image = {pageQuery['hero']}
          icon = {pageQuery['railIcon']}
          subtitle = {"Générez des expériences clients incroyables avec des solutions de surclassement adaptées à votre marque."}
          buttonURL = {'/fr/contact/'}
          buttonLabel = {'Réserver une démonstration'}
          buttonIcon = {pageQuery['rightArrowIcon']}
          fullBG
        />

        <div>
          <PremUpgradeContainer 
            copy = {"Offrez aux passagers la possibilité de faire une offre ou d'acheter un siège premium à travers une expérience utilisateur adaptée à votre marque. Générez des revenus auxiliaires tout en offrant une expérience client incroyable."}
            graphicStyle = {{objectPosition: "center bottom", top: "10%"}}
            image={pageQuery['mobileDevice']}
            fr
          />
        </div>
        <PlatformRevenueContainer>
          <div className="background-con">
            <GatsbyImage fixed={pageQuery['revPlatformBackground'].childImageSharp.fixed} imgStyle={{objectFit: "cover", objectPosition: "top center"}} />
          </div>
          <div className="content-con">
            <h3>La plateforme de revenus auxiliaires pour les opérateurs du rail</h3>
            <div className="icon-content-con">
              <IconContainer 
                icon = {pageQuery['engageIcon']}
                highlight = {'Engagez'}
                headline = {'les passagers à travers de nombreux points de contact'}
                copy = {'Ciblez le bon passager, au bon moment, avec la bonne offre en utilisant l\'intelligence des données et des fonctionnalités de tarification dynamique.'}
              />

              <IconContainer 
                icon = {pageQuery['merchandiseIcon']}
                highlight = {'Proposez'}
                headline = {'des offres diversifiées à vos passagers'}
                copy = {'La technologie Bid-to-Upgrade permet aux passagers de vous dire ce qu\'ils sont prêts à payer, ce qui se traduit par ~4x plus d\'offres soumises.'}
              />

              <IconContainer 
                icon = {pageQuery['optimizeIcon']}
                highlight = {'Optimisation'}
                headline = {'de l\'exécution pour répondre à la demande des passagers'}
                copy = {'Une technologie intelligente de prise de décision sélectionne les offres de passagers à surclasser, maximisant ainsi le potentiel de revenus de l\'ensemble du train.'}
              />
            </div>
          </div>
          
        </PlatformRevenueContainer>
        <PlatformPerksContainer>
          <IconContainer twobytwo
                icon = {pageQuery['announcementIcon']}
                headline = {'Maximisez les revenus\nde la totalité du train'}
                copy = {'Grâce à des outils avancés comme Optimizer, Machine Learning et Dynamic Pricing, notre plateforme est construite pour optimiser les offres et répondre à la demande des passagers.'}
            />

            <IconContainer twobytwo
                icon = {pageQuery['searchIcon']}
                headline = {'Élargissez la visibilité\net l\'engagement'}
                copy = {'L\'intégration transparente de chaque point de contact numérique vous permet d\'engager vos passagers à chaque étape de leur voyage.'}
            />

            <IconContainer twobytwo
                icon = {pageQuery['seatPlusIcon']}
                headline = {'Obtenez des données\nsur les consommateurs'}
                copy = {'Découvrez la sensibilité au prix des passagers et d\'autres informations grâce à la technologie Bid-to-Upgrade.'}
            />

            <IconContainer twobytwo
                icon = {pageQuery['syncIcon']}
                headline = {'Accès aux revenus directs et intégration rapide'}
                copy = {'L\'intégration transparente dans n\'importe quel système vous permet d\'être opérationnel en quelques semaines. Les revenus des programmes de surclassement vous reviennent directement.'}
            />
          
        </PlatformPerksContainer>

        <Content secondaryLightBG >
          <LoyaltyCommerce fr/>
        </Content>

        <PointsLoyalty 
          headline={'Plateforme commerciale de fidélisation'}
          copy={'La Loyalty Commerce Platform de Points, une société de Plusgrade, permet aux membres d\'échanger, de gagner et de convertir leurs points/miles à travers sur des produits auxiliaires de partis tiers ou à travers des collaborations avec d\'autres marques. En connectant votre programme à un réseau de partenaires leaders du secteur, vous pouvez augmenter les revenus auxiliaires et étendre l\'utilité de vos points/miles.'}
          spaceBetween
          fr
        />

        <CallToAction
          title={'Générez des revenus auxiliaires avec des expériences clients incroyables'}
          subtitle={'Contactez-nous aujourd\'hui pour en savoir plus.'}
          > 
          <Button destination={'/fr/contact/demandez-une-demonstration/'} label={'Demandez une démonstration'} icon={pageQuery['rightArrowIcon']} white blueText pill />
        </CallToAction>

      </Content>
    </Layout>
  );
}

export default IndustryRailPage;

const IconCon = styled.div`

${ font.imports.sherika }
  flex-basis: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h4 {
    font-family: ${font.family.sherika};
    font-weight: 600;
    color: ${brand.colors.pg_orange};
    font-size: 30px;
    margin-bottom: 5px;
  }

  .headline-highlight {
    color: ${brand.colors.pg_primary_light};
    margin-top: 30px;
  }

  p{
    margin-top: 50px;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    color: ${brand.colors.pg_primary_light};
  }

  ${props => props.twobytwo && css`
    flex-basis: 47%;
    align-items: flex-start;
    flex-wrap: wrap;

    .headline-con {
      text-align: left;
      h4 {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-weight: 700;
        white-space: pre-line;
      }
      p {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-weight: 400;
        margin: 20px 0;
      }
    }
  `}
`;

const IconContainer = ({icon, highlight, headline, copy, ...props }) => {
  const ic = ( icon ? <GatsbyImage fixed={icon.childImageSharp.fixed}/> : <></>);
  return(
    <IconCon {...props}>
      <div className="icon-con">
        {ic}
      </div>
      <div className="headline-con">
      <h4 className="headline-highlight">{highlight}</h4>
        <h4>
          {headline}
        </h4>
        <p>
          {copy}
        </p>
      </div>

    </IconCon>
  )
}

